import request from '@/utils/request'

export function getList(params) {
  return request({
    url: '/chancePlans/load',
    method: 'get',
    params
  })
}

export function getChancePlan(id) {
  return request({
    url: '/chancePlans/getChancePlan',
    method: 'get',
    params: { id:id }
  })
}

export function loadForRole(roleId) {
  return request({
    url: '/chancePlans/loadForRole',
    method: 'get',
    params: { appId: '', firstId: roleId }
  })
}
export function send(id) {
  return request({
    url: '/chancePlans/Send',
    method: 'get',
    params: {id:id}
  })
}

export function add(data) {
  return request({
    url: '/chancePlans/add',
    method: 'post',
    data
  })
}

export function addNew(data) {
  return request({
    url: '/chancePlans/memberAddNew',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: '/chancePlans/update',
    method: 'post',
    data
  })
}

export function updateNew(data) {
  return request({
    url: '/chancePlans/memberUpdateNew',
    method: 'post',
    data
  })
}

export function del(data) {
  return request({
    url: '/chancePlans/memberdelete',
    method: 'post',
    data
  })
}

