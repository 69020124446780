<template>
	<div class="schemeLibrary">
		<div class="container">
			<h4>方案管理</h4>
			<el-link :underline="false" type="primary" @click="showPri()" v-if="sysMemberclass==false">新建方案</el-link>
			<el-table :data="list" :row-style="{ height: '50px' }" stripe :cell-style="{ textAlign: 'center' }" border
				height="calc(100% - 100px)">
				<el-table-column prop="id" label="方案编号"></el-table-column>
				<el-table-column prop="createTime" label="生成时间"></el-table-column>
				<el-table-column prop="founder" label="创建人"></el-table-column>
				<el-table-column prop="state" label="状态"></el-table-column>
				<el-table-column label="操作">
					<!-- <template slot-scope="scope"> -->
					<template slot-scope="scope">
						<el-link type="primary" :underline="false" @click="check">查看</el-link>
						<el-link type="primary" :underline="false" @click="edit(scope.row)">编辑</el-link>
						<el-link type="primary" :underline="false" @click="del(scope.row)">删除</el-link>
					</template>
				</el-table-column>
			</el-table>

			<pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
				@pagination="handleCurrentChange" />
		</div>
	</div>
</template>

<script>
	import Pagination from "@/components/Pagination";
	import * as categorys from "@/api/categorys";
	// import * as schools from "@/api/schools";
	import * as chanceplans from "@/api/chanceplans";
	export default {
		name: "schools",
		components: {
			Pagination,
		},
		data() {
			return {
				list: null,
				gategoryList: [],
				total: 0,
				listLoading: false,
				sysMemberclass: false,
				schemeList: [{
						num: "NCP_20220316_006407",
						creatTime: "2022-03-16-14:14:56",
						editTime: "2022-03-16-14:14:56",
						founder: "田喜",
						state: "暂存",
					},
					{
						num: "NCP_20220316_006407",
						creatTime: "2022-03-16-14:14:56",
						editTime: "2022-03-16-14:14:56",
						founder: "田喜",
						state: "暂存",
					},
					{
						num: "NCP_20220316_006407",
						creatTime: "2022-03-16-14:14:56",
						editTime: "2022-03-16-14:14:56",
						founder: "田喜",
						state: "暂存",
					},
					{
						num: "NCP_20220316_006407",
						creatTime: "2022-03-16-14:14:56",
						editTime: "2022-03-16-14:14:56",
						founder: "田喜",
						state: "暂存",
					},
					{
						num: "NCP_20220316_006407",
						creatTime: "2022-03-16-14:14:56",
						editTime: "2022-03-16-14:14:56",
						founder: "田喜",
						state: "暂存",
					},
					{
						num: "NCP_20220316_006407",
						creatTime: "2022-03-16-14:14:56",
						editTime: "2022-03-16-14:14:56",
						founder: "田喜",
						state: "暂存",
					},
					{
						num: "NCP_20220316_006407",
						creatTime: "2022-03-16-14:14:56",
						editTime: "2022-03-16-14:14:56",
						founder: "田喜",
						state: "暂存",
					},
					{
						num: "NCP_20220316_006407",
						creatTime: "2022-03-16-14:14:56",
						editTime: "2022-03-16-14:14:56",
						founder: "田喜",
						state: "暂存",
					},
					{
						num: "NCP_20220316_006407",
						creatTime: "2022-03-16-14:14:56",
						editTime: "2022-03-16-14:14:56",
						founder: "田喜",
						state: "暂存",
					},
					{
						num: "NCP_20220316_006407",
						creatTime: "2022-03-16-14:14:56",
						editTime: "2022-03-16-14:14:56",
						founder: "田喜",
						state: "暂存",
					},
					{
						num: "NCP_20220316_006407",
						creatTime: "2022-03-16-14:14:56",
						editTime: "2022-03-16-14:14:56",
						founder: "田喜",
						state: "暂存",
					},
					{
						num: "NCP_20220316_006407",
						creatTime: "2022-03-16-14:14:56",
						editTime: "2022-03-16-14:14:56",
						founder: "田喜",
						state: "暂存",
					},
					{
						num: "NCP_20220316_006407",
						creatTime: "2022-03-16-14:14:56",
						editTime: "2022-03-16-14:14:56",
						founder: "田喜",
						state: "暂存",
					},
					{
						num: "NCP_20220316_006407",
						creatTime: "2022-03-16-14:14:56",
						editTime: "2022-03-16-14:14:56",
						founder: "田喜",
						state: "暂存",
					},
					{
						num: "NCP_20220316_006407",
						creatTime: "2022-03-16-14:14:56",
						editTime: "2022-03-16-14:14:56",
						founder: "田喜",
						state: "暂存",
					},
				],
				listQuery: {
					// 查询条件
					page: 1,
					limit: 20,
					key: undefined,
					appId: undefined,
					syS_ChanceType: "", //机会点类型
					schoolName: "", //学校名称
					schoolCode: "", //学校代码
					syS_Level: "", //学校层级
					arrangement: "",
					enrollmentMajor: "",
					MobileTel: "",
				},
				
			};
		},
		activated() {
			// this.pageFn();
			this.getGategoryList();
			this.getList();
		},
		created(){
			if (JSON.parse(localStorage.getItem("stuInfo")).syS_MemberClass == "2") {
				console.log('success');
				this.sysMemberclass=true;
			}else{
				this.sysMemberclass=false;
			}
		},
		methods: {
			check() {
				console.log("查看");
			},
			showPri() {
				this.$router.push("/enterScheme");
			},
			edit(val) {
				var id = val.id;
				console.log(id);
				this.$router.push({
					path: "/uninewscheme",
					query: {
						id: id
					}
				});
			},
			del(rows) {
				this.$confirm("是否删除?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "info",
				}).then(() => {
					var getList = [];
					getList.push(rows);
					console.log("删除");
					chanceplans.del(getList.map(u => u.id)).then((res) => {
						if (res.code == 200) {
							this.$notify({
								title: '成功',
								message: '删除成功',
								type: 'success',
								duration: 2000
							})
							getList.forEach(row => {
								const index = this.list.indexOf(row);
								this.list.splice(index, 1);
							})
						} else {
							this.$message(res.message);
						}
					}).catch((res) => {
						this.$message.error(res);
					})
				}).catch(() => {
					console.log('取消');
				});
			},
			handleCurrentChange(val) {
				//console.log(val);
				this.listQuery.page = val.page;
				this.listQuery.limit = val.limit;
				this.list = [];
				setTimeout(() => {
					this.pageFn();
				});
			},
			getGategoryList() {
				let listQuery = {
					page: 1,
					limit: 9999,
				};

				categorys.getList(listQuery).then((res) => {
					this.gategoryList = res.data;
				});
			},
			getGategoryName(val, columnName) {
				var dicobject = this.gategoryList.find(
					(t) =>
					t.typeId.toUpperCase() == columnName.toUpperCase() && t.dtValue == val
				);
				if (!dicobject) {
					return "";
				} else {
					//console.log(dicobject.name);
					return dicobject.name;
				}
			},
			getList() {
				//const shelf = this;
				if (JSON.parse(localStorage.getItem("stuInfo")).syS_MemberClass == "2") {
					this.sysMemberclass=true;
				}
				this.list = [];
				this.listQuery.MobileTel = JSON.parse(
					localStorage.getItem("stuInfo")
				).mobiletel;
				//console.log(this.rec[index].shcoolList);
				chanceplans.getList(this.listQuery).then((response) => {
					let list1 = response.data;
					console.log(list1.length);
					for (let i = 0; i < list1.length; i++) {
						var o = list1[i];
						console.log(o);
						this.list.push(o);
					}
					this.total = response.count;
					this.listLoading = false;
				}).catch((res) => {
					console.log(res);
				});
			},
			pageFn() {
				this.list = this.list.slice(
					(this.listQuery.page - 1) * this.listQuery.limit,
					this.listQuery.page * this.listQuery.limit
				);
			},
		},
		computed: {
			// total() {
			//   return this.list.length;
			// },
		},
	};
</script>

<style lang="scss" scoped>
	.schemeLibrary {
		padding: 15px;
		height: calc(100% - 30px);

		.container {
			height: 100%;
			background-color: white;

			h4 {
				background-color: white;
				padding: 10px 20px;
				border-bottom: 1px solid #eee;
				margin: 0;
			}

			.el-table {
				width: calc(100% - 20px);
				margin: 10px;

				.el-link {
					padding: 0 10px;
				}
			}
		}
	}
</style>
